import restaurant from "./images/Ellada.jpg";
import img_card from "./images/img2.jpg";
import imgOmSuflet from "./images/h9.jpg";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/5hm.jpg";
import imgheaderm from "./images/5h.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*anusic007@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Victor",
        mireasa: "Diana",
        data: "26 Noiembrie 2022",
        data_confirmare: "16 noiembrie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "inviatiiweb@gmail.com", 
       tel: "+373xxx xxx xx",
       phone: "tel:+373xxx",
       viber: "viber://chat?number=%2B373xxx",
       whatsapp: "https://wa.me/+373xxx5",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xxx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Ellada",
            data: "26 noiembrie 2022,",
            ziua: "sâmbătă,",
            ora: "ora: 18:00",
            adress: "or. Vasile Lupu 46, Orhei, Moldova",
            harta: "https://goo.gl/maps/5G1z8ZM2x3vDjPUP6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10806.685719538591!2d28.8255125!3d47.379331!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xebd76fc0cdad83ab!2z0K3Qu9C70LDQtNCw!5e0!3m2!1sro!2s!4v1653667369796!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        message1: "P.S. Vă vom fi recunoscători dacă tradiţionalul buchet de flori va fi înlocuit cu flori în ghiveci!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Andrei & Alexandra Crețu",
            parintii_nume2: "Ion & Maria Damian",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Vadim & Andreea Dragomir",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;